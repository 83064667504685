
.v2 {
    width: 45%;
    height: 4px !important;
    background-color: #d4d5d7;
    opacity: 1;
    flex:1;
    margin-top: 19px;
}

