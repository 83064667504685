:root {
  --button_color: #ffed1c;
  --button_label_color: black;
  --footer_backgraund_color: #464749;
  --footer_label_color:white;
  --backgraund_color: #fff;
  --progress_color: #d4d5d7;
  --progress_color_done:#ffed1c;
  --title_color: black;
  --nextui-colors-primary:#ffed1c !important ;

  --nextui-colors-primarySolidHover: #ffed1c !important;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: var(--backgraund_color)!important;
}

html{
  height: 100%;
}
#root{
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row{
  margin: 0px !important;
}

.header{
  display: flex;
  flex-wrap: nowrap;
  padding: 1rem;
  justify-content: center;
  align-items: center;
}

.processTitle{
  color: var(--title_color);
  font-family: "ieteams";
  font-weight: bold;
}

.imgLogo{
 height: 100%;
 padding: 10px 10px 10px 50px;
}
.container_video{
  width:58vw
}

.main_container{
  height: calc(100% - 159px);

}

.container_rec{
  display: flex;
  justify-content: center;
  align-items: center;

}
.checkbox_label{
  margin: 10px 0px;
}
.checkbox_input{
  cursor: pointer;
  margin-right: 10px;
}

@media (max-width: 600px) {
  .progessH {
    border-radius: 100% !important;
    width: 20px !important;
    height: 20px !important;
    background-color: var(--progress_color);
  }
  .footer_container{
    flex-direction: column !important;
    height: 80px !important;
    gap:0px !important;
  }
/* .main_container{

  padding-top: 50px !important;
} */

.imgLogo{
  padding: 10px 10px 10px 10px !important
;
}
.imgContainer{
  display: flex;
  justify-content: center;
}
.v2 {
  width: 45%;
  height: 4px !important;
  background-color:  var(--progress_color) ;
  opacity: 1;
  flex: 1 1;
  margin-top: 9px !important;
}
.header {
  display: block !important;

}
.progressBar{
  width: 80% !important;
}
.container_question{
  top: 65px;
  position: relative;
}
}

.video{
  height:calc(100% - 12px)
}

@font-face {
  font-family: "ieteams";
  src: url(/public/Assets/ieteams.ttf);
}

.container_question{
  height: calc(100% - 221px);
}


.footer_container{
  height: 59px;
  background: var(--footer_backgraund_color);
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 200px;
  justify-content: center;
  align-items: center;
  color: var(--footer_label_color);
  position: absolute;
  bottom: 0px;

}

.progressBar{
  width: 50%;
}

.row{
  padding: 0px !important;
}

.row>* {
  padding: 0px !important;
}





.start_container{
  display: flex;
  justify-content: center;
}

.welcome_title{
  color: black;
  font-family: "ieteams";
  font-weight: bold;
  text-align: center;
}

.start_box{

  
    width: 400px;
    height: 300px;
    justify-content: center;
    border: 2;
    border: 2px solid var(--button_color);
    padding: 20px;
    height: auto;
    box-shadow: 20px 10px var(--button_color);

}

p{
  text-align: justify !important;
}
.start_button:disabled{
  background-color: var(--progress_color);
}
.start_button:disabled:hover{
  cursor: not-allowed;
}
.start_button{
  background-color: var(--button_color);
  border-radius: 10px;
  padding: 10px;
  width: 200px;
  border: 0px
}

.container_finish{
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 68px;
  width: 100%;
}


.buttonRec{
  border: 0px;
  background-color: var(--button_color);
  width: 70px;
  height: 70px;
  border-radius: 100%;
  box-shadow: 0px 0px 20px 0px #0000007a;
}



.stopButton{
  border: 0px;
  background-color: var(--button_color);
  width: 170px;
  height: 70px;
  border-radius: 50px;
  box-shadow: 0px 0px 20px 0px #0000007a;
}

.canvasRec{
  display: flex;
  align-items: center;
  border-radius: 64px;
  width: 176px;
  position: relative;
  height: 100px;
}

canvas{
  border-radius: 20px;
  height: 50px !important;
  width: 119px !important;
  position: absolute;
  left: 15px;
  bottom: 10px;
}


.Record{
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 68px;
  width: 100%;
}


.test_question_title{
  font-size: 20px;
}

.doneH{
  background-color: var(--progress_color_done) !important;
}

.progessH{
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: var(--progress_color);
  box-shadow: -3px 10px 20px 0px #aaaaaa;
}


.v2 {
  width: 45%;
  height: 4px !important;
  background-color:  var(--progress_color);
  opacity: 1;
  flex:1;
  margin-top: 19px;
}

/*Privacy Policies*/

.privacyPolicies {
  padding: 0 10% 5%;
}

.privacyPolicies__title {
  font-size: 1.5rem;
  text-align: center;
}

.privacyPolicies__subtitle {
  font-size: 1.4rem;
  margin-top: 20px;
}

.privacyPolicies__paragraph {
  margin-bottom: 10px;
  line-height: 1.5;
  font-size: 1.2rem
}

.boldText {
  font-weight: bold;
}

.item {
  font-size: 1.2rem
}

.privacyPolicies__containerLogo {
  display: flex;
  justify-content: center;
}

.privacyPolicies__logo {
  width: 200px;
}




